import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Card, Col, Input, Label, Row} from "reactstrap";

// Column
import {withTranslation} from "react-i18next";
import Select from "react-select";
import {useDispatch} from "react-redux";
import {updatePatientAppointment} from "../../store/patientAppointment/actions";
import {
    formatDate,
    formatDateForScreenWithSeperators,
    formatTimeScreen,
    StringToDate
} from "../../common/commonFunctions";
import {postWithoutToken} from "../../helpers/axios_with_headers";

const AppointmentDetail = props => {

    const dispatch = useDispatch();

    const [detail, setDetail] = useState(props.detailObject);
    const [doctor, setDoctor] = useState({});
    const [dateArray, setDateArray] = useState([]);
    const [timeArray, setTimeArray] = useState([]);
    const [date, setDate] = useState(new Date());
    const [time, setTime] = useState("");
    const [doctorOptions, setDoctorOptions] = useState([]);
    const [selectedDate, setSelectedDate] = useState([]);
    const [selectedTime, setSelectedTime] = useState([]);

    useEffect(() => {
        setDetail(props.detailObject);
    }, [props.detailObject]);

    useEffect(() => {
        if (props.detailObject && props.detailObject.appointmentDate) {
            const formattedDate = formatDateForScreenWithSeperators(props.detailObject.appointmentDate, "-", " ");//"2024-03-19 09:45:00"
            const splittedDate = formattedDate.split(" ")[0];
            const splittedTime = formattedDate.split(" ")[1];
            setDate(splittedDate);
            setTime(splittedTime);
            setSelectedDate({label: splittedDate, value: splittedDate});
            setSelectedTime({label: splittedTime, value: splittedTime});
        }
    }, [props.detailObject]);

    useEffect(() => {
        if (props.detailObject && props.detailObject.appointmentUser) {
            setDoctor({label: props.detailObject.appointmentUser.fullName, value: props.detailObject.appointmentUser});
        }
    }, [props.detailObject]);

    useEffect(() => {
        setDoctorOptions(props.doctorOptions);
    }, [props.doctorOptions]);

    useEffect(() => {
        if (dateArray && selectedDate) {
            console.log("selectedDAteEffect", selectedDate)
            dateArray.forEach((item) => {
                    if (item.label === selectedDate.value) {
                        const hours = item.value.hours.map(item => ({label: item, value: item}));
                        setTimeArray(hours);
                    }
                }
            );
        }
    }, [dateArray, selectedDate]);

    const approveRejectAppt = (event) => {
        let apptDate = formatDate(StringToDate(date + " " + time, "DDMMYYYY", "-"));

        const apptReq = {
            ...detail,
            status: event,
            appointmentDate: apptDate,
            appointmentUser: doctor.value
        };
        dispatch(updatePatientAppointment(apptReq));
    };

    function handleChangeNameAndPhone(e) {
        const updatedPatient = {
            ...detail.patient,
            [e.target.name]: e.target.value
        };
        setDetail({
            ...detail,
            ["patient"]: updatedPatient
        });
    }

    function onChangeDoctor(event) {
        setDoctor(event);
    }

    useEffect(() => {
        const getReq = {
            secretKey: "CN}QSa1nn%KNo?.qu?w+qFNyoPk')hE'",
            doctorId: doctor && doctor.value && doctor.value.id
        };

        postWithoutToken(process.env.REACT_APP_API_URL + "/leadIntegration/findAvailableTimes", getReq).then(response => {
            if (response.status === 200 && response.data) {
                setDateArray(response.data.map((event) => ({
                    label: formatDateForScreenWithSeperators(event.date, "-"),
                    value: event
                })));
                console.log("dateArray", dateArray)
            }
        });
    }, [doctor && doctor.value]);

    function onChangeDate(e) {
        setSelectedDate(e);
        setDate(e && e.value ? e.value.date : null);
        setSelectedTime(null);
        const timeOptions = e && e.value && e.value.hours.map((hour) => ({label: formatTimeScreen(hour), value: hour}));
        setTimeArray(timeOptions);
    }

    function onChangeTime(e) {
        setSelectedTime(e);
        setTime(e && e.value ? e.value : null);
    }

    return (
        <React.Fragment>
            <Card>
                <div className="row mt-3">
                    <Col className="col-12">
                        <div className="mb-2">
                            <Label className="form-label">{props.t("Name")}</Label>
                            <Input
                                id="fullName"
                                name="fullName"
                                type="text"
                                onChange={handleChangeNameAndPhone}
                                value={detail && detail.patient && detail.patient.fullName || ""}
                            />
                        </div>
                        <div className="mb-2">
                            <Label className="form-label">{props.t("Phone")}</Label>
                            <Input
                                id="phoneNumber"
                                name="phoneNumber"
                                type="text"
                                onChange={handleChangeNameAndPhone}
                                value={detail && detail.patient && detail.patient.phoneNumber || ""}
                            />
                        </div>
                        <div className="mb-2">
                            <Label className="form-label">{props.t("Specialist")}</Label>
                            <Select
                                value={doctor}
                                onChange={(event) => onChangeDoctor(event)}
                                options={doctorOptions}
                                className="bs-select"
                                isClearable={true}
                            />
                        </div>
                        <div className="mb-2">
                            <div className=" row row-cols-2">
                                <div className="col-md-6">
                                    <Label>{props.t("Date")}</Label>
                                    <Select
                                        id="date"
                                        className="bs-select"
                                        value={selectedDate}
                                        onChange={(e) => onChangeDate(e)}
                                        options={dateArray}
                                        isClearable={true}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Label>{props.t("Time")}</Label>
                                    <Select
                                        id="representHistory"
                                        className="bs-select"
                                        value={selectedTime}
                                        onChange={(e) => onChangeTime(e)}
                                        options={timeArray}
                                        isClearable={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </Col>
                </div>
                <Row className="mt-3">
                    <div className="row text-center">
                        <div className="col-3">
                            <button
                                type="button"
                                className="btn btn-outline-primary btn-lg ms-2"
                                onClick={() => approveRejectAppt("CANCELLED")}
                            >
                                {props.t("Cancel")}
                            </button>
                        </div>
                        <div className="col-3">
                            <button
                                type="button"
                                className="btn btn-outline-danger btn-lg ms-2"
                                onClick={() => approveRejectAppt("REJECTED")}
                            >
                                {props.t("Reject")}
                            </button>
                        </div>
                        <div className="col-3">
                            <button
                                type="button"
                                className="btn btn-outline-success btn-lg ms-2"
                                onClick={() => approveRejectAppt("APPROVED")}
                            >
                                {props.t("Approve")}
                            </button>
                        </div>
                        <div className="col-3">
                            <button
                                type="button"
                                className="btn btn-outline-info btn-lg ms-2"
                                onClick={() => approveRejectAppt("COMPLETED")}
                            >
                                {props.t("Complete")}
                            </button>
                        </div>
                    </div>
                </Row>
            </Card>
        </React.Fragment>
    );
};
AppointmentDetail.propTypes = {
    t: PropTypes.any,
    detailObject: PropTypes.any,
    callback: PropTypes.func
};

export default withTranslation()(AppointmentDetail);
