import React from "react";
import {formatDateForScreen} from "../../common/commonFunctions";

const id = (cell) => {
  return cell.value ? cell.value : "";
};

const Amount = (cell) => {
  return cell.value ? `${parseFloat(cell.value).toFixed(2)} ₺` : "";
};

const Status = (cell) => {
  return cell.value ? cell.value : "";
};

const TaxRate = (cell) => {
  return cell.value ? cell.value : "";
};

const TaxAmount = (cell) => {
  return cell.value ?  `${parseFloat(cell.value).toFixed(2)} ₺` : "";
};

const Month = (cell) => {
  return cell.value ? cell.value : "";
};

const Year = (cell) => {
  return cell.value ? cell.value : "";
};

const PaymentAmount = (cell) => {
  return cell.value ?  `${parseFloat(cell.value).toFixed(2)} ₺`  : "";
};

const PaymentDueDate = (cell) => {
  return cell.value ? formatDateForScreen(cell.value) : "";
};

const ExpectedPaymentDate = (cell) => {
  return cell.value ? formatDateForScreen(cell.value) : "";
};

const PaymentDate = (cell) => {
  return cell.value ? formatDateForScreen(cell.value) : "";
};

const Unit = (cell) => {
  return cell.value ? cell.value : "";
};

export {
  id,
  Amount,
  Status,
  TaxRate,
  TaxAmount,
  Month,
  Year,
  PaymentAmount,
  ExpectedPaymentDate,
  PaymentDueDate,
  PaymentDate,
  Unit
};