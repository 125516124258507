import React, {useEffect, useMemo, useState} from "react";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import {isEmpty} from "lodash";
import {
    Card,
    CardBody,
    Col,
    Container,
    Input,
    InputGroup,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    UncontrolledTooltip
} from "reactstrap";
import withRouter from "../../hooks/withRouter"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import WarningModal from "../../components/Common/WarningModal";
import {
    addNewPayment as onAddNewPayment,
    deletePayment as onDeletePayment,
    getPayments as onGetPayments,
    updatePayment as onUpdatePayment
} from "store/payment/actions";

//redux
import {useDispatch, useSelector} from "react-redux";
import TableContainer from "../../components/Common/TableContainer";

// Column
import {
    Amount,
    ExpectedPaymentDate,
    Month,
    PaymentAmount,
    PaymentDate,
    PaymentDueDate,
    Status,
    TaxAmount,
    TaxRate,
    Unit,
    Year
} from "./paymentCol";
import {withTranslation} from "react-i18next";
import Notification from "../../components/Notification";
import Select from "react-select";
import {
    DateToString,
    formatDate,
    formatDateForScreen,
    formatDateForScreenWithSeperators
} from "../../common/commonFunctions";

const Payment = props => {

    //meta title
    document.title = `CRM | ${props.t("Payment")}`;

    const dispatch = useDispatch();

    const paymentStatusOptions = [{label: props.t("PAID"), value: "PAID"}, {
        label: props.t("UNPAID"),
        value: "NOT_PAID"
    }, {label: props.t("WAITING"), value: "PAYMENT_WAITING"}]

    const initialPayment = {
        status: "",
        taxAmount: 0,
        taxRate: 0,
        discountRate: 0,
        paidAmount: 0,
        userCount: 0,
        month: "",
        year: "",
        description: "",
        unitPrice: 0,
        paymentAmount: 0,
        paymentDate: "",
        paymentDueDate: DateToString(new Date(Date.now()), "YYYY-MM-DD", "-"),
        expectedPaymentDate: DateToString(new Date(Date.now()), "YYYY-MM-DD", "-")
    };

    const {payments, error} = useSelector(state => ({
        payments: state.Payment.payments,
        error: state.Payment.error
    }));

    const [modal, setModal] = useState(false);
    const [paymentList, setPaymentList] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [payment, setPayment] = useState(initialPayment);
    const [firstCall, setFirstCall] = useState(true);
    const [showNotification, setShowNotification] = useState(false);
    const [message, setMessage] = useState("");
    const [notificationType, setNotificationType] = useState("Info");
    const [selectedTax, setSelectedTax] = useState({label: "", value: 0});
    const [selectedStatus, setSelectedStatus] = useState({label: "", value: ""});
    const [selectedDiscountRate, setSelectedDiscountRate] = useState({label: "", value: ""});
    const [disableSave, setDisableSave] = useState(true);

    const handleSubmit=()=>{
        if (isEdit) {
            const updatePayment = {
                id: payment ? payment.id : 0,
                status: payment.status,
                unitPrice: payment.unitPrice,
                userCount: payment.userCount,
                taxAmount: payment.taxAmount,
                taxRate: payment.taxRate,
                month: payment.month,
                year: payment.year,
                paidAmount: payment.paidAmount,
                paymentAmount: payment.paymentAmount,
                paymentDate: payment.paymentDate,
                description: payment.description,
                paymentDueDate: payment.paymentDueDate + " 23:59:59",
                expectedPaymentDate: payment.expectedPaymentDate + " 23:59:59"
            };
            // update payment
            dispatch(onUpdatePayment(updatePayment));
        } else {
            const newPayment = {
                status: payment.status,
                unitPrice: payment.unitPrice,
                userCount: payment.userCount,
                taxAmount: payment.taxAmount,
                taxRate: payment.taxRate,
                month: payment.month,
                year: payment.year,
                paidAmount: payment.paidAmount,
                paymentAmount: payment.paymentAmount,
                paymentDate: payment.paymentDate,
                description: payment.description,
                paymentDueDate: payment.paymentDueDate + " 23:59:59",
                expectedPaymentDate: payment.expectedPaymentDate + " 23:59:59"
            };
            // save new payment
            dispatch(onAddNewPayment(newPayment));
        }
        toggle();
    }

    const handlePaymentClick = arg => {
        const payment = arg;

        setPayment({
            id: payment.id,
            status: payment.status,
            unitPrice: payment.unitPrice,
            userCount: payment.userCount,
            taxAmount: payment.taxAmount,
            taxRate: payment.taxRate,
            discountRate: payment.discountRate,
            paidAmount: payment.paidAmount,
            paymentAmount: payment.paymentAmount,
            month: payment.month,
            year: payment.year,
            description: payment.description,
            paymentDate: payment.paymentDate,
            paymentDueDate: payment.paymentDueDate.split(" ")[0],
            expectedPaymentDate: payment.expectedPaymentDate.split(" ")[0]
        });

        setSelectedTax({
            label: payment.taxRate * 100 +"%" || "",
            value: payment.taxRate || ""
        });

        setSelectedStatus({
            label: payment.status || "",
            value: payment.status || ""
        });

        setIsEdit(true);
        toggle();
    };


    const columns = useMemo(
        () => [
            {
                HeaderLabel: `${props.t("Status")}`,
                Header: "Status",
                accessor: "status",
                filterable: true,
                Cell: (cellProps) => {
                    return <Status {...cellProps} />;
                }
            },
            {
                HeaderLabel: `${props.t("Unit Price")}`,
                Header: "Unit Price",
                accessor: "unitPrice",
                filterable: true,
                Cell: (cellProps) => {
                    return <Amount {...cellProps} />;
                }
            },
            {
                HeaderLabel: `${props.t("Tax Amount")}`,
                Header: "Tax Amount",
                accessor: "taxAmount",
                filterable: true,
                Cell: (cellProps) => {
                    return <TaxAmount {...cellProps} />;
                }
            },
            {
                HeaderLabel: `${props.t("Tax Rate")}`,
                Header: "TaxRate",
                accessor: "taxRate",
                filterable: true,
                Cell: (cellProps) => {
                    return <TaxRate {...cellProps} />;
                }
            },
            {
                HeaderLabel: `${props.t("Unit")}`,
                Header: "User Count",
                accessor: "userCount",
                filterable: true,
                Cell: (cellProps) => {
                    return <Unit {...cellProps} />;
                }
            },
            {
                HeaderLabel: `${props.t("Payment Amount")}`,
                Header: "PaymentAmount",
                accessor: "paymentAmount",
                filterable: true,
                Cell: (cellProps) => {
                    return <PaymentAmount {...cellProps} />;
                }
            },
            {
                HeaderLabel: `${props.t("Payment Date")}`,
                Header: "PaymentDate",
                accessor: "paymentDate",
                filterable: true,
                Cell: (cellProps) => {
                    return <PaymentDate {...cellProps} />;
                }
            },
            {
                HeaderLabel: `${props.t("Expected Payment Date")}`,
                Header: "ExpectedPaymentDate",
                accessor: "expectedPaymentDate",
                filterable: true,
                Cell: (cellProps) => {
                    return <ExpectedPaymentDate {...cellProps} />;
                }
            },
            {
                HeaderLabel: `${props.t("Payment Due Date")}`,
                Header: "PaymentDueDate",
                accessor: "paymentDueDate",
                filterable: true,
                Cell: (cellProps) => {
                    return <PaymentDueDate {...cellProps} />;
                }
            },
            {
                HeaderLabel: `${props.t("Month")}`,
                Header: "Month",
                accessor: "month",
                filterable: true,
                Cell: (cellProps) => {
                    return <Month {...cellProps} />;
                }
            },
            {
                HeaderLabel: `${props.t("Year")}`,
                Header: "Year",
                accessor: "year",
                filterable: true,
                Cell: (cellProps) => {
                    return <Year {...cellProps} />;
                }
            },
            {
                HeaderLabel: `${props.t("Description")}`,
                Header: "Description",
                accessor: "description",
                filterable: true,
                Cell: (cellProps) => {
                    return <Year {...cellProps} />;
                }
            },
            {
                HeaderLabel: `${props.t("isDeletable")}`,
                Header: "Deletable",
                accessor: "deletable",
                filterable: true,
                Cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3">
                            {props.t(`${cellProps.row.original.deletable === false ? "Not Deletable" : "Deletable"}`)}
                        </div>
                    );
                }
            },
            {
                HeaderLabel: `${props.t("Action")}`,
                Header: "Action",
                Cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3">
                            <Link
                                to="#"
                                className="text-success"
                                onClick={() => {
                                    const paymentData = cellProps.row.original;
                                    handlePaymentClick(paymentData);
                                }
                                }
                            >
                                <i className="mdi mdi-pencil font-size-18" id="edittooltip"/>
                                <UncontrolledTooltip placement="top" target="edittooltip">
                                    {props.t("Edit")}
                                </UncontrolledTooltip>
                            </Link>
                            <Link
                                to="#"
                                className="text-danger"
                                onClick={() => {
                                    const paymentData = cellProps.row.original;
                                    onClickDelete(paymentData);
                                }}
                            >
                                <i className="mdi mdi-delete font-size-18" id="deletetooltip"/>
                                <UncontrolledTooltip placement="top" target="deletetooltip">
                                    {props.t("Delete")}
                                </UncontrolledTooltip>
                            </Link>
                        </div>
                    );
                }
            }
        ],
        []
    );

    const handleChange = (e) => {
        if (e.target.type === "date") {
            setPayment({
                ...payment,
                [e.target.name]: e.target.value + "23:59:59"
            });
        }
        if (e.target.name === "paymentAmount") {
            setPayment({
                ...payment,
                ["paymentAmount"]: e.target.value
            });
        } else {
            setPayment({
                ...payment,
                [e.target.name]: e.target.value
            });
        }
    };

    const toggle = () => {
        if (modal) {
            setModal(false);
            setPayment(initialPayment);
            setSelectedDiscountRate(null);
            setSelectedStatus(null);
            setSelectedTax(null);
        } else {
            setModal(true);
        }
    };

    //delete payment
    const [deleteModal, setDeleteModal] = useState(false);

    const onClickDelete = (payment) => {
        setPayment(payment);
        setDeleteModal(true);
    };

    function handleDeletePayment(){
        if (payment.id) {
            dispatch(onDeletePayment(payment.id));
            setDeleteModal(false);
        }
    };

    useEffect(() => {
        if (firstCall) {
            dispatch(onGetPayments());
            setFirstCall(false);
        }
    }, [dispatch, payments]);

    useEffect(() => {
        setPaymentList(payments);
    }, [payments]);

    useEffect(() => {
        if (!isEmpty(payments)) {
            setPaymentList(payments);
        }
    }, [payments]);

    const handlePaymentClicks = () => {
        setPaymentList("");
        setIsEdit(false);
        toggle();
    };

    const handleRefresh = () => {
        dispatch(onGetPayments());
    };

    const handleClose = () => {
        setMessage("");
        setShowNotification(false);
    };

    const onChangeTaxRate = (e) => {
        setSelectedTax(e);
        if (e !== "" && e !== null) {
            setPayment({
                ...payment,
                ["taxRate"]: e.value
            });
        } else {
            setPayment({
                ...payment,
                ["taxRate"]: 0
            });
        }
    };


    useEffect(() => {
        if (error !== undefined && error.data !== undefined) {
            setMessage(error.data.message);
            setShowNotification(true);
            setNotificationType("Danger");
        }
    }, [error]);

    useEffect(() => {
        if (payment.unitPrice !== 0 || payment.unitPrice !== 0) {
            const taxAmount = (payment.taxRate * payment.unitPrice * payment.userCount);
            const totalAmount = ((+payment.unitPrice * payment.userCount) + +taxAmount);
            setPayment({
                ...payment,
                ["taxAmount"]: taxAmount,
                ["paymentAmount"]: totalAmount
            });
        } else {
            setPayment({
                ...payment,
                ["paymentAmount"]: payment.paymentAmount
            });
        }
    }, [payment.taxAmount, payment.taxRate, payment.unitPrice, payment.userCount]);


    function onChangeStatus(e) {
        setSelectedStatus(e);
        setPayment({
            ...payment,
            ["status"]: e.value
        });
    }

    useEffect(() => {
        setDisableSave(payment.expectedPaymentDate === "" || payment.paymentDueDate === "" || payment.paymentAmount === 0 || payment.unitPrice === 0 || payment.month === "" || payment.year === "" || payment.userCount === "" || !selectedStatus || !selectedStatus.value || !selectedTax || !(selectedTax.value || selectedTax.value === 0) )
    }, [payment.expectedPaymentDate, payment.paymentDueDate, payment.paymentAmount, payment.unitPrice, payment.month, payment.year, payment.userCount, selectedStatus && selectedStatus.value, selectedTax && selectedTax.value])

    return (
        <React.Fragment>
            <WarningModal
                show={deleteModal}
                onApproveClick={handleDeletePayment}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content">
                <Container fluid>
                    <Notification
                        message={message}
                        show={showNotification}
                        callbackShow={handleClose}
                        type={notificationType}
                    />
                    <Breadcrumbs title={props.t("Payment")}/>
                    <Row>
                        <Card>
                            <CardBody>
                                <TableContainer
                                    refreshButton={true}
                                    handleRefreshClick={handleRefresh}
                                    columns={columns}
                                    data={payments}
                                    isGlobalFilter={true}
                                    isAddOptions={true}
                                    handleAddClick={handlePaymentClicks}
                                    customPageSize={20}
                                    className="custom-header-css"
                                    paginable={true}
                                />

                                <Modal isOpen={modal} toggle={toggle}>
                                    <ModalHeader toggle={toggle} tag="h4">
                                        {!!isEdit
                                            ? `${props.t("Edit")}`
                                            : `${props.t("Add")}`}
                                    </ModalHeader>
                                    <ModalBody>
                                            <div form="true">
                                                <div className="col-12">
                                                    <div className="mb-3 row">
                                                        <div className="col-6">
                                                            <Label
                                                                className="form-label">{props.t("Unit Price")} <i
                                                                className="mdi mdi-alert-circle-outline error font-size-16 me-1"/></Label>
                                                            <InputGroup>
                                                                <Input
                                                                    name="unitPrice"
                                                                    type="text"
                                                                    onChange={handleChange}
                                                                    value={payment.unitPrice || ""}
                                                                    required={true}
                                                                />
                                                                <Input name="symbol"
                                                                       type="text"
                                                                       disabled="true"
                                                                       value="₺"/>
                                                            </InputGroup>
                                                        </div>
                                                        <div className="col-6">
                                                            <Label className="form-label">{props.t("Unit")} <i
                                                                className="mdi mdi-alert-circle-outline error font-size-16 me-1"/></Label>
                                                            <Input
                                                                name="userCount"
                                                                type="text"
                                                                onChange={handleChange}
                                                                value={payment.userCount || ""}
                                                                required={true}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="mb-3 row">
                                                        <div className="col-6">
                                                            <Label className="form-label">{props.t("Tax Rate")} <i
                                                                className="mdi mdi-alert-circle-outline error font-size-16 me-1"/></Label>
                                                            <Select
                                                                value={selectedTax}
                                                                onChange={(event) => onChangeTaxRate(event)}
                                                                options={[
                                                                    {label: "0%", value: 0}
                                                                    , {label: "10%", value: 0.1}, {
                                                                        label: "20%",
                                                                        value: 0.2
                                                                    }]}
                                                                className="bs-select"
                                                                isClearable={true}
                                                            />
                                                        </div>
                                                        <div className="col-6">
                                                            <Label
                                                                className="form-label">{props.t("Tax Amount")}  <i
                                                                className="mdi mdi-alert-circle-outline error font-size-16 me-1"/></Label>
                                                            <InputGroup>
                                                                <Input
                                                                    name="taxAmount"
                                                                    type="text"
                                                                    disabled="true"
                                                                    onChange={handleChange}
                                                                    value={payment.taxAmount}
                                                                    required={true}
                                                                />
                                                                <Input name="symbol"
                                                                       type="text"
                                                                       disabled="true"
                                                                       value="₺"/>
                                                            </InputGroup>
                                                        </div>
                                                    </div>

                                                    <div className="mb-3 row">
                                                        <Label
                                                            className="form-label">{props.t("Payment Amount")} <i
                                                            className="mdi mdi-alert-circle-outline error font-size-16 me-1"/></Label>
                                                        <InputGroup>
                                                            <Input
                                                                name="paymentAmount"
                                                                type="text"
                                                                onChange={handleChange}
                                                                disabled="true"
                                                                value={payment.paymentAmount || ""}
                                                                required={true}
                                                            />
                                                            <Input name="symbol"
                                                                   type="text"
                                                                   disabled="true"
                                                                   value="₺"/>
                                                        </InputGroup>
                                                    </div>

                                                    <div className="mb-3 row">
                                                        <div className="col-6">
                                                            <Label className="form-label">{props.t("Month")} <i
                                                                className="mdi mdi-alert-circle-outline error font-size-16 me-1"/></Label>
                                                            <Input
                                                                name="month"
                                                                type="text"
                                                                onChange={handleChange}
                                                                value={payment.month || ""}
                                                            />
                                                        </div>
                                                        <div className="col-6">
                                                            <Label
                                                                className="form-label">{props.t("Year")} <i
                                                                className="mdi mdi-alert-circle-outline error font-size-16 me-1"/></Label>
                                                            <Input
                                                                name="year"
                                                                type="text"
                                                                onChange={handleChange}
                                                                value={payment.year || ""}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="mb-3 row">
                                                        <div className="col-6">
                                                            <Label
                                                                className="form-label">{props.t("Expected Payment Date")}
                                                                <i
                                                                    className="mdi mdi-alert-circle-outline error font-size-16 me-1"/></Label>
                                                            <Input
                                                                name="expectedPaymentDate"
                                                                type="date"
                                                                onChange={handleChange}
                                                                value={payment.expectedPaymentDate}
                                                            />
                                                        </div>
                                                        <div className="col-6">
                                                            <Label
                                                                className="form-label">{props.t("Payment Due Date")} <i
                                                                className="mdi mdi-alert-circle-outline error font-size-16 me-1"/></Label>
                                                            <Input
                                                                name="paymentDueDate"
                                                                type="date"
                                                                onChange={handleChange}
                                                                value={payment.paymentDueDate}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="mb-3 row">
                                                        <Label className="form-label">{props.t("Status")} <i
                                                            className="mdi mdi-alert-circle-outline error font-size-16 me-1"/></Label>
                                                        <Select
                                                            value={selectedStatus}
                                                            onChange={(event) => onChangeStatus(event)}
                                                            options={paymentStatusOptions}
                                                            className="bs-select"
                                                            isClearable={true}
                                                        />
                                                    </div>

                                                    <div className="m-1 row">
                                                        <Label className="form-label">{props.t("Description")}</Label>
                                                        <Input
                                                            name="description"
                                                            type="text"
                                                            onChange={handleChange}
                                                            value={payment.description || ""}
                                                        />
                                                    </div>
                                                </div>
                                                <Row>
                                                    <Col>
                                                        <div className="text-end">
                                                            <button
                                                                type="submit"
                                                                className="btn btn-success save-customer"
                                                                disabled={disableSave}
                                                                onClick={handleSubmit}
                                                            >
                                                                {props.t("Save")}
                                                            </button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                    </ModalBody>
                                </Modal>
                            </CardBody>
                        </Card>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};
Payment.propTypes = {
    payments: PropTypes.array,
    onGetPayments: PropTypes.func,
    onAddNewPayment: PropTypes.func,
    onDeletePayment: PropTypes.func,
    onUpdatePayment: PropTypes.func
};

export default withRouter(withTranslation()(Payment));
